//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Day",
  props: {
    dates: {
      type: Array
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isMenstrualData: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedIndex: -1,
    countDay: 7,
    selectedDate: [],
    isUpdate: false
  }),
  mounted() {
    if (this.$route.name === "menstrual-calendar-selection-update" || this.$route.name === "menstrual-calendar-selection") {
      this.isUpdate = true;
    }
  },
  methods: {
    daySelectionClick(active, date, index, data) {
      if (!active) return;
      if (data.isMenstruation) {
        this.$emit("on-unselected", new Date(date.getFullYear(), date.getMonth(), date.getDate()), index);
        return;
      }
      this.$emit("on-selected", new Date(date.getFullYear(), date.getMonth(), date.getDate()), index);
    },
    selectDayClick(active, date, index, data) {
      if (index === this.selectedIndex) {
        active && this.$emit("on-remove-select", date, index);
        this.selectedIndex = -1;
      } else {
        active && this.$emit("on-day-select", date, index, data);
        this.selectedIndex = index;
      }
    },
    setClass(date) {
      return {
        "selected-day": date !== undefined && date.isSelected && !this.isUpdate,
        selected: date !== undefined && date.isSelected && this.isUpdate,
        day: date !== undefined && date.thisMonth,
        none: !(date !== undefined && date.thisMonth),
        menstruation: date !== undefined && date.data.isMenstruation && date.thisMonth,
        prediction: date !== undefined && date.data.isPrediction && date.thisMonth,
        fertility: date !== undefined && date.data.isFertility && date.thisMonth
      };
    }
  }
};